import React,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { MdOutlineLocationOn, MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [formData, setFormData] = useState({
    fName: '',
    lName: '',
    email: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const res = await axios.post('https://techamerica-server.vercel.app/api/contact-us', formData);

      if (res.data.success) {
        toast.success('Your message has been sent. Thank you!');
        setFormData({
          fName: '',
          lName: '',
          email: '',
          subject: '',
          message: ''
        });
      } else {
        setError('Failed to send message.');
        toast.error('Failed to send message.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  return (
    <main>
      <ToastContainer />
      <div className="relative w-full h-64">
        <img
          src="/contact.svg"
          alt="Contact Banner"
          className="absolute inset-0 z-0 object-fill w-full h-full"
        />
        <div className="absolute inset-0 bg-primary opacity-50 z-10"></div>
        <div className="relative z-20 flex items-center justify-center h-full">
          <div className="section-header-page">
            <h2>Contact Us</h2>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 my-4">
        <iframe
          style={{ border: 0, width: "100%", height: "270px" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3260.5135908456955!2d-96.98218139422653!3d32.993883105709365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2ec22bf478a9%3A0x8608560766751839!2s2785%20Rockbrook%20Dr%20STE%20307%2C%20Lewisville%2C%20TX%2075067%2C%20USA!5e0!3m2!1sen!2s!4v1717183426597!5m2!1sen!2s"
          title='Google Map'
          allowFullScreen>
        </iframe>
        <section className="contact relative py-24 bg-left-top bg-no-repeat bg-contain">
          <div className="container relative" data-aos="fade-up">
            <div className="flex flex-wrap justify-end gap-4">
              <div className="lg:w-5/12" data-aos="fade-up" data-aos-delay="100">
                <div className="info-item flex mb-10">
                  <i className="flex-shrink-0 bg-primary text-white w-11 h-11 flex justify-center items-center rounded-full transition-all duration-300 ease-in-out mr-4 text-xl">
                    <MdOutlineLocationOn />
                  </i>
                  <div>
                    <h4 className="text-xl font-bold text-tertiary mb-1">Location:</h4>
                    <p className="text-sm">
                      <Link to="https://goo.gl/maps/4U9tQyuefjJ2" target="blank">
                        2785 Rockbrook Dr STE 307 Lewisville, TX, USA
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="info-item flex mb-10">
                  <i className="flex-shrink-0 bg-primary text-white w-11 h-11 flex justify-center items-center rounded-full transition-all duration-300 ease-in-out mr-4 text-xl">
                    <MdOutlineMailOutline />
                  </i>
                  <div>
                    <h4 className="text-xl font-bold text-tertiary mb-1">Email:</h4>
                    <p className="text-sm">
                      <Link to="mailto:info@techamericallc.com">
                        info@techamericallc.com
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="info-item flex mb-10">
                  <i className="flex-shrink-0 bg-primary text-white w-11 h-11 flex justify-center items-center rounded-full transition-all duration-300 ease-in-out mr-4 text-xl">
                    <MdOutlinePhone />
                  </i>
                  <div>
                    <h4 className="text-xl font-bold text-tertiary mb-1">Call:</h4>
                    <p className="text-sm">
                      <Link to="tel:+1 214 494-9567">
                        +1 (214) 494-9567
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-6/12" data-aos="fade-up" data-aos-delay="250">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                      <input type="text" value={formData.fName} onChange={handleChange} name="fName" className="form-input outline-none block w-full p-2 border rounded" placeholder="First Name" required />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mt-3 md:mt-0">
                      <input type="text" value={formData.lName} onChange={handleChange} className="form-input outline-none block w-full p-2 border rounded" name="lName" placeholder="Last Name" required />
                    </div>
                  </div>
                  <div className="mt-3">
                    <input type="email" value={formData.email} onChange={handleChange} className="form-input outline-none block w-full p-2 border rounded" name="email" placeholder="Email" required />
                  </div>
                  <div className="mt-3">
                    <input type="text" value={formData.subject} onChange={handleChange} className="form-input outline-none block w-full p-2 border rounded" name="subject" placeholder="Subject" required />
                  </div>
                  <div className="mt-3">
                    <textarea value={formData.message} onChange={handleChange} className="form-input outline-none block w-full p-2 border rounded" name="message" rows="5" placeholder="Message" required></textarea>
                  </div>
                  <div className="my-3">
                    {loading && <div className="loader text-center p-4"></div>}
                    {error && <div className="error-message text-white bg-red-600 text-left p-4 font-bold">{error}</div>}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary bg-primary text-white px-8 py-3 rounded transition duration-400 hover:bg-secondary" disabled={loading}>
                      {loading ? "Sending..." : "Send Message"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  )
}

export default Contact
