"use client";
import { menuTabs } from "../utils";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

export default function Navbar() {
  const location = useLocation();
  const path = location.pathname;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const isActive = (href) => {
    return path === href;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav className="sticky top-0 bg-white p-4 z-50">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="text-primary text-2xl font-bold">
          <Link to="/">
            <img src="/logo.PNG" alt="Tech America" width={150} height={50} />
          </Link>
        </div>
        <div className="md:hidden">
          <button onClick={toggleSidebar} className="focus:outline-none text-primary">
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        <ul className="hidden md:flex md:flex-row space-x-4 text-black">
          {menuTabs.map(tab => (
            <li key={tab.href}>
              <Link to={tab.href} className={`nav-link ${isActive(tab.href) ? "active" : ""}`}>
                {tab.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/* Sidebar */}
      <div className={`fixed top-0 right-0 h-full bg-white shadow-lg z-50 transform ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <button onClick={toggleSidebar} className="absolute top-4 right-4 focus:outline-none text-primary">
          <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <ul className="flex flex-col items-start p-6 space-y-4">
          {menuTabs.map(tab => (
            <li key={tab.href}>
              <Link to={tab.href} className={`nav-link ${isActive(tab.href) ? "active" : ""}`} onClick={toggleSidebar}>
                {tab.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
