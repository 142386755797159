import React, { useEffect } from 'react'
import { FaCloud, FaTools, FaRobot, FaShieldAlt, FaUserFriends } from 'react-icons/fa';

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main>
      <div>
        {/* Banner Section */}
        <div className="relative w-full h-64">
          <img
            src="/connecting.svg"
            alt="Services Banner"
            className="absolute inset-0 z-0 object-fill w-full h-full"
          />
          <div className="absolute inset-0 bg-primary opacity-50 z-10"></div>
          <div className="relative z-20 flex items-center justify-center h-full">
            <div className="section-header-page">
              <h2>Services</h2>
            </div>
          </div>
        </div>

        <div className="container mx-auto p-6 md:p-12">
          <div className="section-header mb-6 text-center">
            <h2 className="text-3xl md:text-4xl font-bold">Our Services</h2>
          </div>
          <div className="space-y-12">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="md:w-1/2 mb-6 md:mb-0">
                <h2 className="text-2xl md:text-2xl text-primary font-bold mb-4">Tech America</h2>
                <p className="text-md md:text-lg mb-4">
                  Tech America builds end-to-end solutions that help businesses accelerate product innovation, optimize operations, and increase customer engagement. Our uniquely experienced team of data scientists, engineers, and architects are SMEs in change enablement, and we tailor each solution to your business environment to help you get more customer-focused products to market, faster. We can also help you accelerate your data and analytics solutions through our consulting partnerships with AWS, Google Cloud, Microsoft, and Splunk®.
                </p>
              </div>
              <div className="md:w-1/2 flex justify-end">
                <img
                  src="/services.svg"
                  alt="Services at Tech America"
                  className="rounded-md"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="flex items-start">
                <FaCloud className="text-6xl text-primary mr-4" />
                <div>
                  <h3 className="text-xl font-bold">Data Platform Modernization</h3>
                  <p>Our experienced migration team ensures data platforms are built with cost, performance, and scale in mind to address changing business needs.</p>
                </div>
              </div>
              <div className="flex items-start">
                <FaTools className="text-6xl text-primary mr-4" />
                <div>
                  <h3 className="text-xl font-bold">MLOps</h3>
                  <p>Access a framework for development and operations teams that dramatically accelerates the ML development lifecycle.</p>
                </div>
              </div>
              <div className="flex items-start">
                <FaRobot className="text-6xl text-primary mr-4" />
                <div>
                  <h3 className="text-xl font-bold">AIOps</h3>
                  <p>Bring AI to your IT operations to reduce time-to-resolution and predict outages before they impact your business.</p>
                </div>
              </div>
              <div className="flex items-start">
                <FaShieldAlt className="text-6xl text-primary mr-4" />
                <div>
                  <h3 className="text-xl font-bold">Fraud Detection</h3>
                  <p>Implement a fully integrated AI/ML fraud model to proactively detect and respond to fraudulent activity — no matter how complex.</p>
                </div>
              </div>
              <div className="flex items-start">
                <FaUserFriends className="text-6xl text-primary mr-4" />
                <div>
                  <h3 className="text-xl font-bold">Digital Customer</h3>
                  <p>Leverage modern platforms and AI/ML to anticipate your customer&apos;s needs and deliver personalized, omnichannel experiences.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Services
