import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      {/* Banner Section */}
      <div className="relative w-full h-64">
        <img
          src="/career-banner.svg"
          alt="Career Banner"
          className="absolute inset-0 z-0 object-fill w-full h-full"
        />
        <div className="absolute inset-0 bg-primary opacity-50 z-10"></div>
        <div className="relative z-20 flex items-center justify-center h-full">
          <div className="section-header-page">
            <h2>Careers At <span className='font-bold'> <span className='text-primary'>Tech</span>America </span></h2>
          </div>
        </div>
      </div>

      <div className="container flex flex-col md:flex-row items-center justify-between mx-auto p-6 md:p-12">
        <div className="md:w-1/2 mb-6 md:mb-0">
          <h2 className="text-2xl md:text-2xl text-primary font-bold mb-4">Join TechAmerica</h2>
          <h2 className="text-2xl md:text-[48px] leading-[1] text-gray-700 font-bold mb-4">
            We&apos;re looking for <span className='primary-doodle'>passionate talents </span> to join our dynamic team
          </h2>
          <p className="text-md md:text-lg mb-4">
            We believe in bringing together the best and brightest talents we can find in the industry - people who are passionate about their skills and can bring a diverse background to a dynamic team. If you&apos;re looking for a company that aligns itself with the value of individual talent within team collaboration in delivering real business and technology solutions, then we&apos;re looking for you.
          </p>
          <p className="text-md md:text-lg mb-4">
            We also believe in the constancy of learning. Our employees keep pace with the rapidly changing industry by staying ahead of the learning curve. We provide the tools, information, and support you need to expand your knowledge base and continue to grow.
          </p>
        </div>
        <div className="md:w-1/2 flex justify-end">
          <img
            src="/career.svg"
            alt="Career at TechAmerica"
            className="rounded-md"
          />
        </div>
      </div>
      <div className="container mx-auto p-6 md:p-12">

        <p className="text-md md:text-lg mb-4">
          We&apos;re also proud to have one of the best compensation packages in the industry. The market may fluctuate, but business software and technology continues to evolve and expand every day. We succeed through exceptional quality, teamwork, professionalism and our compensation practices reflect our commitment to these ideals.
        </p>
        <p className="text-md md:text-lg font-bold mb-4">
          Find where you want to start: check out our Current Openings.
        </p>
        <Link to="/current-openings" className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-secondary">
          Current Openings
        </Link>
        <p className="text-md md:text-lg mt-6">
          Please e-mail your resume or inquiries to:{" "}
          <Link to="mailto:careers@techamericallc.com" className="text-blue-500 underline">
            careers@techamericallc.com
          </Link>{" "}
          for positions in the U.S.
        </p>
      </div>
    </div>
  )
}

export default Careers
