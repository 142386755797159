import { FaBriefcase, FaChartBar, FaBinoculars, FaDatabase, FaProjectDiagram, FaBrain, FaShieldAlt, FaUsers } from "react-icons/fa";
import { GrUserExpert } from "react-icons/gr";
export const menuTabs = [
    { href: "/", label: "Home" },
    { href: "/about-us", label: "About Us" },
    { href: "/services", label: "Services" },
    { href: "/training", label: "Training" },
    { href: "/careers", label: "Careers" },
    { href: "/contact", label: "Contact" },
];
export const partners = [
    { src: "/partners/directv.png", alt: 'directv' },
    { src: "/partners/janssen.png", alt: 'janssen' },
    { src: "/partners/guartdant.jpg", alt: 'guartdant' },
    { src: "/partners/dbt.png", alt: 'dbt' },
    { src: "/partners/snowflake.png", alt: 'Snowflake' },
  ];


export const services = [
  {
    icon: <FaDatabase className="text-primary text-4xl mb-4" />,
    title: "Data Platform Modernization",
    description: "Our experienced migration team ensures data platforms are built with cost, performance and scale in mind to address changing business needs.",
  },
  {
    icon: <FaProjectDiagram className="text-primary text-4xl mb-4" />,
    title: "MLOps",
    description: "Access a framework for development and operations teams that dramatically accelerates the ML development lifecycle.",
  },
  {
    icon: <FaBrain className="text-primary text-4xl mb-4" />,
    title: "AIOps",
    description: "Bring AI to your IT operations to reduce time-to-resolution and predict outages before they impact your business.",
  },
  {
    icon: <FaShieldAlt className="text-primary text-4xl mb-4" />,
    title: "Fraud Detection",
    description: "Implement a fully integrated AI/ML fraud model to proactively detect and respond to fraudulent activity — no matter how complex.",
  },
  {
    icon: <FaUsers className="text-primary text-4xl mb-4" />,
    title: "Digital Customer",
    description: "Leverage modern platforms and AI/ML to anticipate your customers needs and deliver personalized, omnichannel experiences.",
  },
];
export const whyChooseUs = [
    {
        icon: <FaBriefcase className="text-primary text-4xl" />,
        title: "Expertise",
        description: "Our team of experienced consultants brings deep expertise in data and analytics across various industries, ensuring tailored solutions to meet each client's unique challenges.",
    },
    {
        icon: <GrUserExpert className="text-primary text-4xl" />,
        title: "Client-Centric Approach",
        description: "We prioritize understanding our clients' business objectives and collaborate closely with them to deliver customized solutions that drive measurable results.",
    },
    {
        icon: <FaChartBar className="text-primary text-4xl" />,
        title: "Innovation and Quality",
        description: "We are committed to delivering innovative solutions of the highest quality, leveraging the latest technologies and best practices to exceed client expectations.",
    },
    {
        icon: <FaBinoculars className="text-primary text-4xl" />,
        title: "Long-Term Partnerships",
        description: "We strive to build enduring partnerships with our clients, providing ongoing support and guidance to help them navigate the complexities of the data landscape and achieve sustained success.",
    }
];