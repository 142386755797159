import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './screens/Home'
import AboutUs from './screens/AboutUs'
import Services from './screens/Services'
import Training from './screens/Training'
import Careers from './screens/Careers'
import Contact from './screens/Contact'
import './index.css';
import CurrentOpenings from './screens/CurrentOpenings'

const App = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/training" element={<Training />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/current-openings" element={<CurrentOpenings />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
