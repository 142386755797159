import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const CurrentOpenings = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            {/* Banner Section */}
            <div className="relative w-full h-64">
                <img
                    src="/career-banner.svg"
                    alt="Career Banner"
                    className="absolute inset-0 z-0 object-fill w-full h-full"
                />
                <div className="absolute inset-0 bg-primary opacity-50 z-10"></div>
                <div className="relative z-20 flex items-center justify-center h-full">
                    <div className="section-header-page">
                        <h2>Open Positions at <span className='font-bold'> <span className='text-primary'>Tech</span>America </span></h2>
                    </div>
                </div>
            </div>

            <section className='mt-14'>
                <div className="section-header">
                    <h2 className="text-3xl md:text-4xl font-bold text-center">Open Positions</h2>
                </div>

                <div className='container mx-auto'>
                    <div className='text-center text-2xl font-bold text-primary mt-8 border-2 p-2'>
                        No Open Positions At The Moment
                    </div>
                </div>
                {/* Currently there is no open position, leave a email */}
                <div className="container mx-auto p-6 md:p-12">
                    <p className="text-md md:text-lg mb-4">
                        We currently do not have any open positions. However, we are always looking for talented individuals to join our team. If you are interested in working with us, please send us your resume at{" "}
                        <Link to="mailto:info@techamericallc.com" className="text-blue-500 underline">
                            info@techamericallc.com
                        </Link>
                    </p>
                </div>
            </section>
        </div>
    )
}

export default CurrentOpenings
