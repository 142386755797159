import React, { useEffect } from 'react'
import { BiTargetLock } from "react-icons/bi";
import { FaHandshake, FaUserFriends, FaLightbulb, FaUsers } from 'react-icons/fa';
import { whyChooseUs } from '../utils';
import { FaChartLine, FaShieldAlt, FaProjectDiagram, FaChartPie, FaChartBar, FaRobot } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      {/* Banner Section */}
      <div className="relative w-full h-64">
        <img
          src="/team.svg"
          alt="About Us Banner"
          className="absolute inset-0 z-0 object-fill w-full h-full"
          
        />
        <div className="absolute inset-0 bg-primary opacity-50 z-10"></div>
        <div className="relative z-20 flex items-center justify-center h-full">
          <div className="section-header-page">
            <h2>About Us</h2>
          </div>
        </div>
      </div>


      <div className="container flex flex-col md:flex-row items-center justify-between mx-auto p-6 md:p-12">
        <div className="md:w-1/2 mb-6 md:mb-0">
          <h2 className="text-2xl md:text-2xl text-primary font-bold mb-4">TechAmerica</h2>
          <h2 className="text-2xl md:text-[48px] leading-[1] text-gray-700 font-bold mb-4">a premier <span className='primary-doodle'>consulting firm </span> specializing in data and analytics solutions</h2>
          <p className="text-md md:text-lg mb-4">
            Founded with a vision to empower businesses with trust and reliability in their data-driven decisions, we are committed to delivering exceptional value to our clients through innovative strategies and customer-centric approaches.
          </p>
          <Link to="/contact" className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-secondary">
            Contact Us
          </Link>
        </div>
        <div className="md:w-1/2 flex justify-end">
          <img
            src="/main-image.png"
            alt="Descriptive Alt Text"
            // width={500}
            // height={300}
            className="rounded-md"
          />
        </div>
      </div>

      <section className='mb-14'>
        <div className="section-header">
          <h2 className="text-3xl md:text-4xl font-bold text-center">Our Mission</h2>
        </div>
        <div className="container mx-auto p-6 md:p-12 relative my-6">
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-gray-300 text-[4rem] sm:text-[6rem] md:text-[10rem] lg:text-[15rem] font-bold opacity-20">MISSION</span>
          </div>
          <div className="relative z-10">
            <div className="flex flex-col md:flex-row items-center w-full md:w-[60%] mx-auto text-center md:text-left">
              <div className="text-6xl md:text-8xl mb-4 md:mb-0 md:mr-4">
                <BiTargetLock />
              </div>
              <p className="text-lg md:text-xl">
                At <span className='text-primary font-bold'>TechAmerica,</span> our mission is to provide unparalleled consulting services,
                leveraging the power of data and analytics to drive meaningful business
                outcomes for our clients. We are dedicated to building long-term partnerships
                based on trust, integrity, and a deep understanding of our clients needs.
              </p>
            </div>
          </div>
        </div>
      </section>


      <div className="container mx-auto p-6 md:p-12 flex flex-col md:flex-row items-center gap-8">
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <img src="/core-values.jpg" alt="Core Values" className="w-full h-auto "  />
        </div>
        <div className="w-full md:w-1/2">
          <div className="section-header mb-6">
            <h2 className="text-3xl md:text-4xl font-bold">Our Values</h2>
          </div>
          <div className="space-y-4">
            <div className="flex items-start">
              <FaHandshake className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Trust and Reliability</h3>
                <p>We believe in the integrity of data and strive to earn the trust of our clients through reliable and transparent practices.</p>
              </div>
            </div>
            <div className="flex items-start">
              <FaUserFriends className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Customer Orientation</h3>
                <p>Our clients are at the heart of everything we do. We prioritize their needs and tailor our solutions to deliver maximum value and satisfaction.</p>
              </div>
            </div>
            <div className="flex items-start">
              <FaLightbulb className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Innovation</h3>
                <p>We embrace innovation as a driving force behind progress. Through continuous learning and adaptation, we stay at the forefront of technology and industry trends to deliver cutting-edge solutions.</p>
              </div>
            </div>
            <div className="flex items-start">
              <FaUsers className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Collaboration</h3>
                <p>Collaboration is key to our success. We foster a culture of teamwork, encouraging open communication and knowledge sharing among our consultants and clients.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <section className="mb-[50px]">
        <div className="container mx-auto p-6 md:p-12">
          <div className="section-header mb-6 text-center">
            <h2 className="text-3xl md:text-4xl font-bold">Our Services</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex items-start">
              <FaChartLine className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Data Strategy and Planning</h3>
                <p>TechAmerica offers a comprehensive suite of consulting services to develop and execute a data strategy that aligns with your business goals.</p>
              </div>
            </div>
            <div className="flex items-start">
              <FaShieldAlt className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Data Governance and Compliance</h3>
                <p>We ensure your data practices meet regulatory requirements and industry standards, safeguarding your data&apos;s integrity and security.</p>
              </div>
            </div>
            <div className="flex items-start">
              <FaProjectDiagram className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Advanced Analytics and Predictive Modeling</h3>
                <p>Our experts leverage advanced analytics and predictive modeling to uncover actionable insights and drive data-driven decision-making.</p>
              </div>
            </div>
            <div className="flex items-start">
              <FaChartPie className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Business Intelligence and Reporting</h3>
                <p>We provide robust business intelligence solutions and reporting services to help you track performance and make informed decisions.</p>
              </div>
            </div>
            <div className="flex items-start">
              <FaChartBar className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Data Visualization and Dashboarding</h3>
                <p>Our data visualization and dashboarding services transform complex data into intuitive, easy-to-understand visuals.</p>
              </div>
            </div>
            <div className="flex items-start">
              <FaRobot className="text-6xl text-primary mr-4" />
              <div>
                <h3 className="text-xl font-bold">Machine Learning and Artificial Intelligence</h3>
                <p>We harness the power of machine learning and artificial intelligence to develop innovative solutions that drive business growth.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-[150px]">
        <div className="section-header">
          <h2>Why Choose Us</h2>
        </div>
        <div className="services">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8">
              {whyChooseUs.map((service, index) => (
                <div key={index} className="icon-box bg-background p-6 rounded-lg hover:shadow-md">
                  <div className="flex">
                    <div className="icon mb-4 mr-6">{service.icon}</div>
                    <div>
                      <h4 className="text-xl font-bold mb-2">
                        <div className="text-gray-800  duration-300">{service?.title}</div>
                      </h4>
                      <p className="text-gray-600">{service?.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutUs
