import { Link } from "react-router-dom";
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaTwitterSquare, FaWhatsappSquare, FaYoutubeSquare } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

const Footer = () => {
    return (
        <footer className="footer bg-tertiary py-8 text-white">
            <div className="footer-top">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">

                        <div className="footer-contact">
                            <h3 className="text-2xl mb-2 font-bold">Tech America<span className="text-primary">.</span></h3>
                            <p className="mb-4">
                             2785 Rockbrook Dr STE 307 <br />
                                Lewisville, TX<br />
                                United States <br /><br />
                                <strong>Phone:</strong> <Link to="tel:+12144949567">+1 (214) 494-9567</Link> <br />
                                <strong>Email:</strong>{" "} <Link to="mailto:info@techamericallc.com">info@techamericallc.com</Link> <br />
                            </p>
                        </div>

                        <div className="footer-links">
                            <h4 className="text-xl mb-2 font-bold">Useful Links</h4>
                            <ul>
                                <li><Link to="/" className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>Home</Link></li>
                                <li><Link to="/about-us" className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>About us</Link></li>
                                <li><Link to="/services" className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>Services</Link></li>
                                <li><Link to="/contact" className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>Contact</Link></li>
                                <li><Link to="/careers" className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>Careers</Link></li>
                            </ul>
                        </div>

                        <div className="footer-links">
                            <h4 className="text-xl mb-2 font-bold">Our Services</h4>
                            <ul>
                                <li><div className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>Data Platform Modernization</div></li>
                                <li><div className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>MLOps</div></li>
                                <li><div className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>AIOps</div></li>
                                <li><div className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>Fraud Detection</div></li>
                                <li><div className="flex items-center"><span className="inline-block mr-1 text-primary"><IoIosArrowForward /></span>Digital Customer</div></li>
                            </ul>
                        </div>

                        <div className="footer-links">
                            <h4 className="text-xl mb-2 font-bold">Our Social Networks</h4>
                            <div className="flex justify-between space-x-4">
                                <Link to="https://www.facebook.com" target="_blank" className="text-white">
                                    <FaFacebookSquare size={30} />
                                </Link>
                                <Link to="https://www.twitter.com" target="_blank" className="text-white">
                                    <FaTwitterSquare size={30} />
                                </Link>
                                <Link to="https://www.linkedin.com" target="_blank" className="text-white">
                                    <FaLinkedin size={30} />
                                </Link>
                                <Link to="https://www.instagram.com" target="_blank" className="text-white">
                                    <FaInstagramSquare size={30} />
                                </Link>
                                <Link to="https://www.youtube.com" target="_blank" className="text-white">
                                    <FaYoutubeSquare size={30} />
                                </Link>
                                <Link to="https://www.whatsapp.com" target="_blank" className="text-white">
                                    <FaWhatsappSquare size={30} />
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="text-center mt-8">
                <div className="mb-4">&copy; Copyright <Link to='' className="hover:text-primary underline">Tech America</Link>. All Rights Reserved</div>
                <div className="text-sm">Designed & Developed by <Link to="https://www.linkedin.com/in/alirazahub" target="_blank" className="hover:text-primary underline font-bold">Ali Raza</Link></div>
            </div>
        </footer>
    );
}

export default Footer;
