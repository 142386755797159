import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Training = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      {/* Banner Section */}
      <div className="relative w-full h-64">
        <img
          src="/task.svg"
          alt="Training Banner"
          className="absolute inset-0 z-0 object-fill w-full h-full"
        />
        <div className="absolute inset-0 bg-primary opacity-50 z-10"></div>
        <div className="relative z-20 flex items-center justify-center h-full">
          <div className="section-header-page">
            <h2>Trainings</h2>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-6 md:p-12">
        <div className="section-header mb-6 text-center">
          <h2 className="text-3xl md:text-4xl font-bold">Training Services</h2>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-6 md:mb-0">
            <h2 className="text-2xl md:text-2xl text-primary font-bold mb-4">Tech America Training</h2>
            <p className="text-md md:text-lg mb-4">
              Tech America is a consulting and training company with operations throughout the USA. If your firm has a development team that needs training or technical consulting services, we are eager to be of service. Our website contains everything you need to make an informed choice when selecting a consulting or developer training partner.
            </p>
            <p className="text-md md:text-lg mb-4">
              We offer a wide range of courses every month in SAP, Oracle Financials, SQL Server, Java-based technologies, .NET-based technologies, QA testing, and more. Our developer training is some of the best you will find. We use only our own full-time instructors, and we construct our own course material by hand.
            </p>
            <p className="text-md md:text-lg font-bold mb-4">
              If you are interested, we request you to send an email to:{" "}
              <Link to="mailto:training@techamericallc.com" className="text-blue-500 underline">
                training@techamericallc.com
              </Link>.
            </p>
            <p className="text-md md:text-lg mb-4">
              Once we receive your request, a representative will contact you to discuss your training needs, requested course dates, and more.
            </p>
          </div>
          <div className="md:w-1/2 flex justify-end">
            <img
              src="/training.svg"
              alt="Training at Tech America"
              className="rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Training
