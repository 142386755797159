import React, { useEffect } from 'react'
import {  partners } from "../utils";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main>
      <section className="hero d-flex align-items-center">
        <div className="flex  justify-end">
          <div className=" pl-10 w-full xl:w-1/3 z-50">
            <h2>IT Consulting Services</h2>
            <blockquote >
              <p>
                A company journey from strategy discovery through to delivery of technologies and services.
              </p>
            </blockquote>
            <div className="d-flex" >
              <Link to='/contact' className="btn-get-started">Contact Us</Link>
            </div>
          </div>
        </div>
      </section>


      <div className="container flex flex-col md:flex-row items-center justify-between mx-auto p-6 md:p-12">
        <div className="md:w-1/2">
          <img
            src="/banner2.jpg"
            alt="Descriptive Alt Text"
            className="rounded-md"
          />
        </div>
        <div className="md:w-1/2 mb-6 md:mb-0 ml-6">
          <h2 className="text-2xl md:text-[48px] leading-[1] text-gray-700 font-bold mb-4">IT Services That <span className='primary-doodle'>Empower</span> Businesses</h2>
          <p className="text-md md:text-lg mb-4">
            If you do not have the proper IT partner, either internal or external, your business could face multiple IT challenges. Technologies are complex and always changing, yet your reliance on it is only increasing. IT can empower your business to run efficiently 24/7, but to do that, you need an expert team that delivers.
          </p>
        </div>
      </div>
      <section className="mb-[150px]">
        <div className="section-header">
          <h2>Our Technology Services</h2>
        </div>

        <div className="container mx-auto px-4">
          We can guide you to a Business Anywhere future one where every interaction is smart and seamless, every process is fully automated and paperless, and everyone is ready for whatever the future holds. Together we can plan and build a solution that is right for you.
          <div className="mt-10 mb-4 text-primary font-bold text-2xl">
            IT Solutions
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/qa.jpeg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">QA & Testing Solutions</div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/iot.jpeg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">IOT Solutions</div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/python.jpg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">Python Development</div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/mob.jpg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">Mobility Solutions</div>
            </div>
          </div>

          <div className="mt-10 mb-4 text-primary font-bold text-2xl">
          AI and Cloud Solutions
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/ai.jpeg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">
                Artificial Intelligence & Machine Learning
              </div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/big.jpeg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">
                Big Data
              </div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/cloud.jpg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">
                Cloud Solutions
              </div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/cyber.jpeg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">
                Cyber Sececurity Solutions</div>
            </div>
          </div>

          <div className="mt-10 mb-4 text-primary font-bold text-2xl">
          Enterprise Solutions
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/ent.jpg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">Enterprise Data Management</div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/sale.png"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">Salesforce Services</div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/dig.jpeg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">Digital Transformation</div>
            </div>
            <div className=" bg-gray-50">
              <div className="w-[100%] rounded-t-md">
                <img
                  src="/sec.jpeg"
                  alt="it"
                  className="rounded-t-md"
                />
              </div>
              <div className="text-gray-800 p-3 font-semibold rounded-b-md">Cyber Security</div>
            </div>
          </div>
        </div>
      </section>



      <section className="my-[150px]">
        <div className="section-header">
          <h2>Our Partners</h2>
        </div>
        <div className="container mx-auto px-4">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={0}
            slidesPerView={3}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            loop={true}
          >
            {partners.map((partner, index) => (
              <SwiperSlide key={index}>
                <div className="flex justify-center items-center w-100">
                  <img  src={partner?.src} alt={partner?.alt} className="h-full object-contain" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </main>
  )
}

export default Home
